import React from "react";
import { SEO, PageLayout, Background, ZupplerMenu } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class MenuPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      fishermanBusinessProviderServiceSetting,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"menu-hero"}>
              <Background.Image
                width={16}
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
        >
          <Grid
            stackable={true}
            className={"menu-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={"16"} textAlign={"left"}>
              <ZupplerMenu
                dataChannelUrl={
                  fishermanBusinessProviderServiceSetting.serviceData
                    .dataChannelUrl
                }
                dataIntegration={
                  fishermanBusinessProviderServiceSetting.serviceData
                    .dataIntegration
                }
                dataRestaurantId={
                  fishermanBusinessProviderServiceSetting.serviceData
                    .dataRestaurantId
                }
                dataLocale={
                  fishermanBusinessProviderServiceSetting.serviceData.dataLocale
                }
                dataPortalSlices={
                  fishermanBusinessProviderServiceSetting.serviceData
                    .dataPortalSlices
                }
                isMultipleRestaurant={
                  fishermanBusinessProviderServiceSetting.serviceData
                    .isMultipleRestaurant
                }
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Menu" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    fishermanBusinessProviderServiceSetting(
      provider: { eq: "Zuppler" }
      serviceType: { eq: "Menu" }
    ) {
      serviceData {
        dataChannelUrl
        dataIntegration
        dataRestaurantId
        dataLocale
        dataPortalSlices
        isMultipleRestaurant
      }
    }
  }
`;
